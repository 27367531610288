import auth from './AuthService'
import {domain} from './FetchService'

class APIService {
  static inst = null

  static getInstance () {
    if (APIService.inst === null) {
      this.inst = new APIService()
    }

    return this.inst
  }

  getImagesPath () {
    return domain + '/images'
  }

  getImagesPathPOI (imageName) {
    return this.getImagesPath() +
      '/pois/' + imageName
  }

  async getCategories () {
    // Ask the API to get all the categories
    return auth.fetch(
      '/categories')
  }

  async getPOIs () {
    return auth.fetch(
      '/poisCategories')
  }

  async createPOI (poi) {
    // Ask the API to create a POI
    var data = new FormData()

    for (let key in poi) {
      if (key === 'categories') {
        data.append(key, JSON.stringify(poi[key]))
      } else {
        data.append(key, poi[key])
      }
    }

    // The headers is necessary to make
    // fetch not create default headers
    return auth.fetch('/pois', {
      method: 'POST',
      headers: { },
      body: data
    })
  }

  async updatePOI (poi) {
    // Ask the API to update a POI
    let data = new FormData()
    const idPOI = poi.idPOI

    for (let key in poi) {
      if (key === 'categories') {
        data.append(key, JSON.stringify(poi[key]))
      } else {
        data.append(key, poi[key])
      }
    }

    return auth.fetch(`/pois/${idPOI}`, {
      method: 'PUT',
      headers: { },
      body: data
    })
  }

  async deletePOI (idPOI) {
    return auth.fetch(
      '/pois/' + idPOI, {
      method: 'DELETE'
    })
  }
}

export default APIService.getInstance()
