import React, { Component } from 'react'
import {
  withRouter
} from 'react-router-dom'
import {
  Button
} from 'react-bootstrap'

import api from '../../services/APIService'

class POIListItem extends Component {
  constructor (props) {
    super(props)

    this.poi = this.props.data

    this.edit = this.edit.bind(this)
    this.onDelete = this.onDelete.bind(this)
  }

  edit () {
    const poi = this.poi
    this.props.history.push(
      '/admin/POIForm', { poi }
    )
  }

  async onDelete (event) {
    event.stopPropagation()

    const res = window.confirm("Confirmer la supression de ce point d'intérêt")
    if (!res) return

    const idPOI = this.poi.idPOI
    try {
      await api.deletePOI(idPOI)

      // Tell the parent list to update
      this.props.removePOI(this.poi)
    } catch (error) {
      console.error(error)
    }
  }

  render () {
    return (
      <tr onClick={this.edit}>
        <th scope='row'>{this.poi.name}</th>
        <td>{this.poi.short_description}</td>
        <td>
          <Button
            onClick={this.onDelete}
            variant="link"
          >
            Supprimer
          </Button>
        </td>
      </tr>
    )
  }
}

export default withRouter(POIListItem)
