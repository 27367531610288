import React, { Component } from 'react'
import {
  Container
} from 'react-bootstrap'

import api from '../../services/APIService'
import POIList from './POIList'

export default class POIListWrapper extends Component {
  constructor () {
    super()

    this.state = {
      isFetching: true,
      pois: undefined
    }

    this.removePOI = this.removePOI.bind(this)
  }

  componentDidMount () {
    this.getPOIs()
  }

  async getPOIs () {
    const pois = await api.getPOIs()

    this.setState({
      isFetching: false,
      pois: pois
    })
  }

  removePOI (poiToRemove) {
    // Used to update the list without doing
    // a new API call
    let pois = this.state.pois.filter( (poi) => {
      return poi.idPOI !== poiToRemove.idPOI
    })
    this.setState({ pois: pois })
  }

  render () {
    return (
      <Container className='mt-5 mb-5'>
        { this.state.isFetching ? (
          <p>Chargement de la liste des
          points d'intérêts...</p>
        ) : (
          <POIList
            removePOI={this.removePOI}
            pois={this.state.pois}
          />
        ) }
      </Container>
    )
  }
}
