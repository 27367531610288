/* 
  Standard directive to let it know that
  localStorage and fetch are defined at 
  a global level.
*/
/* global localStorage */
import decode from 'jwt-decode'

import domainFetch from './FetchService.js'

class AuthService {
  static inst = null

  static getInstance () {
    if (AuthService.inst === null) {
      this.inst = new AuthService()
    }

    return this.inst
  }

  login (login, password) {
    // Get a token from api
    // server using the fetch api
    return this.fetch('/login', {
      method: 'POST',
      body: JSON.stringify({
        login,
        password
      })
    }).then(res => {
      // Setting the token in localStorage
      this.setToken(res.token)
      return Promise.resolve(res)
    })
  }

  loggedIn () {
    // Checks if there is a saved token
    // and it's still valid.
    // Getting token from localstorage
    const token = this.getToken()
    // Handwaiving here
    return !!token && !this.isTokenExpired(token)
  }

  isTokenExpired (token) {
    try {
      const decoded = decode(token)
      // Checking if token is expired. N
      if (decoded.exp < Date.now() / 1000) {
        return true
      } else { return false }
    } catch (err) {
      return false
    }
  }

  setToken (idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
  }

  getToken () {
    // Retrieves the user
    // token from localStorage
    return localStorage.getItem('id_token')
  }

  logout () {
    // Clear user token and
    // profile data from localStorage
    localStorage.removeItem('id_token')
  }

  getProfile () {
    // Using jwt-decode npm
    // package to decode the token
    return decode(this.getToken())
  }

  fetch (url, options) {
    // Performs api calls sending the
    // required authentication headers

    if (options === undefined) {
      options = { }
    }

    if (options.headers === undefined) {
      // Default headers
      options.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      options.headers['Authorization'] =
        'Bearer ' + this.getToken()
    }

    return domainFetch(url, options)
      .then(this._checkStatus)
      .then(response => response.json())
  }

  _checkStatus (response) {
    // raises an error in case
    // response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response
    } else {
      var error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }
}

export default AuthService.getInstance()
