const domain = process.env.REACT_APP_API_URL || 'https://184.ip-51-83-69.eu'

export {domain}

if (process.env.NODE_ENV !== 'production') {
  console.log('Using API endpoint: ' + domain)
}

/* Improve fetch function by using
 * the domain passed to the app as an
 * environment variable.
 */
export default async (url, options) => {
  return fetch(domain + url, options)
}
