import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Table,
} from 'react-bootstrap'

import POIListItem from './POIListItem'

export default class POIList extends Component {
  render () {
    if (this.props.pois.length <= 0) {
      // When there is no POI in the database
      return (
        <div>
          <p>
            Aucun POI dans la base de données.
          </p>

          <Link to='/admin/POIForm'>
            Cliquer ici pour
            créer un nouveau POI
          </Link>
        </div>
      )
    } else {
      return (
        <Table hover>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Courte description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            { this.props.pois.map((POI) =>
              <POIListItem
                removePOI={this.props.removePOI}
                key={POI.idPOI}
                data={POI}
              />
            ) }
          </tbody>
        </Table>
      )
    }
  }
}
