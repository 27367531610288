import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Navbar,
  Nav
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import auth from '../../services/AuthService'

class NavBar extends Component {
  constructor () {
    super()

    this.onClickLogout = this.onClickLogout.bind(this)
  }

  onClickLogout () {
    auth.logout()
    this.props.history.replace('/')
  }

  render () {
    return (
      <Navbar bg='light' expand='lg'>
        <Navbar.Brand
          href='#home'
        >
            <img
              src='/logo.png'
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Napoléon Paris Logo"
            />
            Napoléon Paris
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>

            <LinkContainer to='/admin/POIList'>
              <Nav.Link>
                Liste des POI
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to='/admin/POIForm'>
              <Nav.Link>
                Ajouter un POI
              </Nav.Link>
            </LinkContainer>

            <Nav.Link onClick={this.onClickLogout}>
              Déconnexion
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default withRouter(NavBar)
