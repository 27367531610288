import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
  Formik,
  Field,
  FieldArray,
  ErrorMessage
} from 'formik'
import * as Yup from 'yup'
import {
  Container,
  Form,
  Col,
  Button,
  Image
} from 'react-bootstrap'

import './POIForm.css'
import api from '../../services/APIService'

const required = 'Ce champ est requis'

let schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Le nom est trop court')
    .max(200, 'Le nom est trop long')
    .required(required),
  short_description: Yup.string()
    .max(200, 'La description est trop longue')
    .required(required),
  latitude: Yup.number('Chiffre invalide')
    .typeError('La latitude doit être un nombre')
    .required(required),
  longitude: Yup.number('Chiffre invalide')
    .typeError('La longitude doit être un nombre')
    .required(required),
  categories: Yup.array()
    .min(1, 'Au moins une catégorie doit être sélectionnée')
    .required(required)
})

var toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'align': [] }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  ['link', 'image', 'video'],

  ['clean']                                         // remove formatting button
]

class POIForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isFetching: true,
      image_path: undefined
    }

    this.fileInput = React.createRef()

    this.isEditing =
      this.props.location !== undefined &&
      this.props.location.state !== undefined &&
      this.props.location.state.poi !== undefined

    if (this.isEditing) {
      this.poiToEdit =
        this.props.location.state.poi
    }

    if (!this.isEditing) {
      schema = schema.concat(Yup.object().shape({
        image: Yup.mixed()
          .required(required)
      }))

      this.initialValues = {
        name: '',
        image: '',
        short_description: '',
        long_description: '',
        latitude: '',
        longitude: '',
        categories: []
      }
    } else {
      // Set defaults values to the poi passed as a props
      this.initialValues = {
        ...this.poiToEdit,
        image: ''
      }
    }
  }

  async componentDidMount () {
    const categories = await api.getCategories()

    const purgedCategories = categories.filter( (category) => {
      if (category.name === 'Mes favoris' ||
          category.name === 'Tout Napoléon Paris') {
        return false
      }
      return true
    })

    this.setState({
      isFetching: false,
      categories: purgedCategories
    })
  }

  async onSubmit (values) {
    const poi = {
      ...values,
      image: this.fileInput.current.files[0]
    }

    if (this.isEditing) {
      poi.idPOI = this.poiToEdit.idPOI

      api.updatePOI(poi)
        .then(() => {
          this.props.history.replace('/admin/POIList')
        })
        .catch(err => {
          console.error(err)

          // TODO: display error
        })
    } else {
      api.createPOI(poi)
        .then(() => {
          this.props.history.replace('/admin/POIList')
        })
        .catch(err => {
          console.error(err)

          // TODO: display error
        })
    }
  }

  render () {
    return (
      <Container className='mt-5 mb-5'>
        <Formik
          initialValues={this.initialValues}
          validationSchema={schema}
          onSubmit={values => {
            this.onSubmit(values)
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Nom du point d'intérêt</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.name && touched.name}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name='name' />
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type='file'
                  name='image'
                  ref={this.fileInput}
                  value={values.image}
                  onChange={(e) => {
                    const files = e.target.files
                    let image_path = undefined

                    handleChange(e)

                    if (files.length > 0) {
                      image_path = URL.createObjectURL(e.target.files[0])
                    }

                    this.setState({
                      image_path: image_path
                    })
                  }}
                  onBlur={handleBlur}
                  isInvalid={!!errors.image}
                />

                { this.state.image_path ? (
                  <div className='thumbnail'>
                    <Image fluid
                      src={this.state.image_path}
                    />
                  </div>
                ) : null }
                { this.isEditing && !this.state.image_path ? (
                  <div className='thumbnail'>
                    <Image fluid
                      src={api.getImagesPathPOI(this.poiToEdit.image_name)}
                    />
                  </div>
                ) : null }

                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name='image' />
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Courte description</Form.Label>
                <Form.Control
                  type='text'
                  name='short_description'
                  value={values.short_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.short_description && touched.short_description}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name='short_description' />
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Longue description</Form.Label>
                <Field name='long_description'>
                  {({ field }) =>
                    <ReactQuill
                      defaultValue={field.value}
                      value={field.value}
                      onChange={field.onChange(field.name)} 
                      onBlur={field.onBlur(field.name)}
                      rows="5"
                      modules={{ toolbar: toolbarOptions }}
                    />
                  }
                </Field>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name='long_description' />
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                      type='decimal'
                      name='latitude'
                      value={values.latitude}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.latitude && touched.latitude}
                    />
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name='latitude' />
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      type='decimal'
                      name='longitude'
                      value={values.longitude}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.longitude && touched.longitude}
                    />
                    <Form.Control.Feedback type="invalid">
                      <ErrorMessage name='longitude' />
                    </Form.Control.Feedback>
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group>
                <Form.Label className='mb-0'>
                  Catégories de ce point d'intérêts
                </Form.Label>
                <Form.Text className='mb-3 text-muted'>
                  Cocher les catégories auquel appartient ce point
                  d'intérêt. Il peut appartenir à plusieurs
                  catégories.
                </Form.Text>
                { this.state.isFetching ? (
                  <p>Récupération des catégories...</p>
                ) : (
                  <FieldArray
                    name='categories'
                    render={(arrayHelpers) => (
                      this.state.categories.map((category) =>
                        <Form.Check
                          name='categories'
                          type='checkbox'
                          label={category.name}
                          key={category.idCategory}
                          custom
                          value={category.idCategory}
                          id={category.idCategory}
                          checked={values.categories.includes(category.idCategory)}
                          onChange={e => {
                            if (e.target.checked) arrayHelpers.push(category.idCategory)
                            else {
                              const idx = values.categories.indexOf(category.idCategory)
                              arrayHelpers.remove(idx)
                            }
                          }}
                        />
                      ) 
                    )}
                  />
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.categories}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant='primary'
                type='submit'>
                  {
                    this.isEditing
                      ? "Mettre à jour le point d'intérêt"
                      : "Créer le point d'intérêt"
                  }
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    )
  }
}

export default withRouter(POIForm)