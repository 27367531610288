import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import withAuth from '../withAuth'
import NavBar from '../NavBar/NavBar'
import POIListWrapper from '../POI/POIListWrapper'
import POIForm from '../POI/POIForm'

class AdminPanel extends Component {
  render () {
    return (
      <div>
        <NavBar />

        <Route path='/admin/POIList'
          component={POIListWrapper} />
        <Route path='/admin/POIForm'
          component={POIForm} />
        <Route exact path='/admin'
          component={POIListWrapper} />
      </div>
    )
  }
}

export default withAuth(AdminPanel)
