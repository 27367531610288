import React, { Component } from 'react'
import {
  Form,
  Button,
  Alert
} from 'react-bootstrap'

import './Login.css'
import auth from '../../services/AuthService'
import RenderIf from '../RenderIf/RenderIf'

class Login extends Component {
  constructor () {
    super()
    this.state = {
      username: '',
      password: '',
      error: undefined
    }

    this.handleChange =
      this.handleChange.bind(this)
    this.handleFormSubmit =
      this.handleFormSubmit.bind(this)
  }

  componentWillMount () {
    if (auth.loggedIn()) {
      this.props.history.replace('/admin')
    }
  }

  handleChange (e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }

  handleFormSubmit (e) {
    e.preventDefault()
    this.setState({ error: undefined })

    auth.login(this.state.username, this.state.password)
      .then(res => {
        this.props.history.replace('/admin/POIList')
      })
      .catch(err => {
        console.error(err.response)
        let message = 'Une erreur inconnue est survenue...'

        if (err.response) {
          switch (err.response.status) {
            case 401: message = 'Identifiant ou mot de passe invalide'; break
            default: break
          }
        }

        this.setState({ error: message })
      })
  }

  render () {
    return (
      <div className='center'>
        <div className='wrapper'>
          <img className='logo' alt='Logo Napoléon Paris' src='./logo.png' />
          <h2 className='title'>Napoléon Paris</h2>

          <RenderIf if={this.state.error}>
            <Alert
              variant="danger"
              onClose={() => this.setState({ error: undefined })}
              dismissible
          >
              <Alert.Heading>Oups...</Alert.Heading>
              <p>
                {this.state.error}
              </p>
            </Alert>
          </RenderIf>

          <Form>
            <Form.Group controlId='username'>
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control
                type='text'
                name='username'
                placeholder="Nom d'utilisateur"
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId='password'>
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type='password'
                name='password'
                placeholder="Mot de passe"
                onChange={this.handleChange}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={this.handleFormSubmit}
            >
              Connexion
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}

export default Login
